import { supabase } from './supabase.js';

let cachedSession = null;
let cachedSubscription = null;
let lastChecked = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export async function getAuthState() {
    const now = Date.now();

    if (cachedSession && now - lastChecked < CACHE_DURATION) {
        return { session: cachedSession, subscription: cachedSubscription };
    }

    try {
        const { data, error } = await supabase.auth.getSession();
        if (error) {
            console.error('Error getting session:', error);
            throw error;
        }

        const session = data.session;

        if (!session) {
            console.warn('No session found. Clearing auth state.');
            clearAuthState();
            return { session: null, subscription: null };
        }

        // Optionally refresh the session if needed
        /*
        console.log('Refreshing session.');
        const { data: refreshData, error: refreshError } = await supabase.auth.refreshSession();

        if (refreshError) {
            console.error('Error refreshing session:', refreshError);
            clearAuthState();
            return { session: null, subscription: null };
        }

        const refreshedSession = refreshData.session;
        console.log('Refreshed session:', refreshedSession);
        */

        cachedSession = session; // Use session directly without refreshing
        cachedSubscription = await checkUserSubscription(session.user.id);
        lastChecked = now;

        return { session: cachedSession, subscription: cachedSubscription };
    } catch (error) {
        console.error('Error in getAuthState:', error);
        clearAuthState();
        return { session: null, subscription: null };
    }
}

export async function checkAuth() {
    try {
        const { session, subscription } = await getAuthState();
        const urlParams = new URLSearchParams(window.location.search);
        const planParam = urlParams.get('plan');

        if (!session) {
            console.warn('No session found in checkAuth.');
            return { session: null, subscription: null, shouldRedirect: false };
        }

        // If there's a plan parameter, user is attempting to subscribe
        if (planParam) {
            return { session, subscription, shouldRedirect: false };
        }

        // Check for all-day subscription if user has no regular subscription
        if (!subscription) {
            const allDaySubscription = await checkAllDaySubscription(session.user.id);
            if (allDaySubscription) {
                return { session, subscription: allDaySubscription, shouldRedirect: false };
            }
        }

        return { session, subscription, shouldRedirect: false };
    } catch (error) {
        console.error('Unexpected error in checkAuth:', error);
        console.error('Error stack:', error.stack);
        return { session: null, subscription: null, shouldRedirect: false };
    }
}

async function checkAllDaySubscription(userId) {
    try {
        const { data, error } = await supabase
            .from('subscriptions')
            .select('subscription_id, user_id, price_id, start_date, end_date, status, is_current, plan_type')
            .eq('user_id', userId)
            .eq('plan_type', 'all-day')
            .eq('is_current', true)
            .order('created_at', { ascending: false })
            .limit(1);

        if (error) {
            console.error('Error fetching all-day subscription:', error);
            return null;
        }

        if (data && data.length > 0) {
            const subscription = data[0];
            const now = new Date();
            const startDate = new Date(subscription.start_date);
            const endDate = new Date(subscription.end_date);

            if (now >= startDate && now <= endDate && subscription.status === 'active') {
                return subscription;
            } else {
                console.warn('All-day subscription is not active or not within valid date range.');
            }
        } else {
            // console.warn('No all-day subscription data found.');
        }

        return null;
    } catch (error) {
        console.error('Error in checkAllDaySubscription:', error);
        console.error('Error stack:', error.stack);
        return null;
    }
}

async function checkUserSubscription(userId) {
    try {
        const { data, error } = await supabase
            .from('subscriptions')
            .select('*')
            .eq('user_id', userId)
            .eq('is_current', true)
            .order('created_at', { ascending: false })
            .limit(1);

        if (error) {
            console.error('Error fetching subscription:', error);
            console.error('Full error object:', JSON.stringify(error, null, 2));
            return null;
        }

        if (data && data.length > 0) {
            const subscription = data[0];

            // Check if the subscription is still valid
            const now = new Date();
            const endDate = new Date(subscription.end_date);
            if (endDate > now && subscription.status === 'active') {
                return subscription;
            } else {
                console.warn('Subscription is not active or expired.');
                return null;
            }
        } else {
            // console.warn('No subscription data found.');
        }

        return null;
    } catch (error) {
        console.error('Unexpected error in checkUserSubscription:', error);
        console.error('Error stack:', error.stack);
        return null;
    }
}

export function saveSession(session) {
    if (session && session.access_token) {
        document.cookie = `supabase-auth-token=${session.access_token}; path=/; secure; SameSite=Strict`;
    } else {
        console.warn('No session or access token to save.');
    }
}

export async function signOut() {
    try {
        await supabase.auth.signOut();
        clearAuthState();
        clearSession();
        localStorage.removeItem('supabase.auth.token');
        document.cookie = `supabase-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    } catch (error) {
        console.error('Error during sign out:', error);
    }
}

export function clearAuthState() {
    cachedSession = null;
    cachedSubscription = null;
    lastChecked = 0;
}

export function clearSession() {
    document.cookie = `supabase-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function showAlert(message) {
    const alertContainer = document.getElementById('alertContainer');
    const alertMessage = document.getElementById('alertMessage');
    const alertTitle = document.getElementById('alertTitle');
    
    if (alertContainer && alertMessage && alertTitle) {
        alertTitle.textContent = "Information";
        alertMessage.textContent = message;
        alertContainer.classList.remove('hidden');
    } else {
        console.warn('Alert elements not found in DOM.');
    }
}

export function setupDismissAlertButton() {
    const dismissAlertButton = document.getElementById('dismissAlertButton');
    if (dismissAlertButton) {
        dismissAlertButton.addEventListener('click', () => {
            const alertContainer = document.getElementById('alertContainer');
            if (alertContainer) {
                alertContainer.classList.add('hidden');
            }
            localStorage.removeItem('alertMessage');
        });
    } else {
        // console.warn('Dismiss alert button not found.');
    }
}

export async function handleCheckoutCancellation() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('canceled') === 'true') {
        await signOut();
        showAlert('Your subscription process was canceled. You can try again when you are ready.');
        return true;
    }
    return false;
}

export async function getUserData(userId) {
    try {
        const { data, error } = await supabase
            .from('users')
            .select('name, email, profile_picture_url')
            .eq('user_id', userId)
            .limit(1);

        if (error) {
            console.error('Error fetching user data:', error);
            return null;
        }

        if (!data || data.length === 0) {
            console.error('No user data found for the provided user_id');
            return null;
        }

        return data[0];
    } catch (error) {
        console.error('Error in getUserData:', error);
        return null;
    }
}

export { supabase };